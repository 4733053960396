/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

@import "~firebaseui/dist/firebaseui.css";
@import "./assets/scss/material-theme.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;

  font-size: 1.5rem;

  i {
    font-size: 2rem;
  }
}
